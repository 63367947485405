import { ChangeEvent, FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';

import {
  TextField,
  Icon,
  Popper,
  POPPER_PLACEMENT,
  Button,
  getSizeRem,
  Theme,
  ButtonAsButtonProps,
  getScrollStyles,
} from '@/components/Core';

export type SelectOption = { id: number | string; name: string };
export interface SelectProps extends Omit<ButtonAsButtonProps, 'value'> {
  value: SelectOption | null;
  setValue: (value: SelectOption) => void;
  options: SelectOption[];
  placeholder: string;
  hasOptionsFilter?: boolean;
  filterCurrentOption?: boolean;
  isOpen: boolean;
  toggleIsOpen: () => void;
  close: () => void;
  position?: POPPER_PLACEMENT;
  buttonProps?: ButtonAsButtonProps;
}

const StyledWrapper = styled.div(
  ({ theme }) => `
  width: 100%;
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.primary[25]};
  border-radius: ${getSizeRem(theme.sizes.xxs)};
  box-shadow: 2px -6px 6px 0px #001D850D, 0px 12px 16px 0px #001D8514;
  overflow: hidden;
  ul {
    list-style: none;
    width: 100%;
    max-height: ${getSizeRem(300)};
    overflow: auto;
    ${getScrollStyles(theme)}
  }
  & label {
    margin-inline: ${getSizeRem(theme.sizes.s)};
    margin-bottom: ${getSizeRem(theme.sizes.xxs)};
  }
`
);

const getOpenStyles = (isOpen: boolean, theme: Theme) => {
  if (isOpen) {
    return `
    color: ${theme.palette.grey[700]};
    box-shadow: 2px -6px 6px 0px #001D850D, 0px 12px 16px 0px #001D8514;
      svg {
        transform: rotate(180deg);
      }
      `;
  }
};

const StyledButton = styled(Button)<ButtonAsButtonProps & { isOpen: boolean }>(
  ({ theme, isOpen }) => `
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: ${getSizeRem(theme.fontSizes.s)};
      color: ${theme.palette.grey[900]};
      padding-inline: ${getSizeRem(theme.sizes.s)};
      ${getOpenStyles(isOpen, theme)}
    `
);

const StyledOption = styled.button(
  ({ theme }) => `
  border: none;
  cursor: pointer;
  text-align: start;
  background-color: ${theme.palette.white};
  width: 100%;
  padding-block: ${getSizeRem(theme.sizes.s / 2)};
  padding-inline: ${getSizeRem(theme.sizes.s)};
  font-size: ${getSizeRem(theme.fontSizes.s)};
  font-family: inherit;
  line-height: inherit;
  &:hover, &:focus {
    background-color: ${theme.palette.primary[50]};
  }
  &:disabled {
    background-color: ${theme.palette.grey[50]};
  }
`
);

export const Select: FC<SelectProps> = ({
  value,
  setValue,
  toggleIsOpen,
  close,
  options,
  hasOptionsFilter = false,
  filterCurrentOption = true,
  isOpen,
  placeholder,
  position = POPPER_PLACEMENT.B_START,
  ...props
}) => {
  const [filter, setFilter] = useState('');
  const [inputValue, setInputValue] = useState('');

  const visibleOptions = useMemo(
    () => options.filter((el) => el.name.toLowerCase().includes(filter)),
    [filter, options]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilter = useCallback(
    debounce(async (value: string) => {
      setFilter(value);
    }, 500),
    []
  );
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    debouncedFilter(value.toLowerCase());
  };

  const handleSelect = useCallback(
    (option: SelectOption) => {
      setValue(option);
      toggleIsOpen();
    },
    [setValue, toggleIsOpen]
  );

  const handleClear: MouseEventHandler = (event) => {
    event.stopPropagation();
    if (!inputValue) return;
    setFilter('');
    setInputValue('');
  };

  return (
    <Popper
      isOpen={isOpen}
      placement={position}
      anchor={
        <StyledButton
          variant="filled"
          color="secondary"
          type="button"
          icon="menu-down"
          iconAlign="right"
          onClick={toggleIsOpen}
          isOpen={isOpen}
          {...props}
        >
          {value?.name || placeholder}
        </StyledButton>
      }
      close={close}
    >
      <StyledWrapper>
        {hasOptionsFilter && (
          <TextField
            name="filter"
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            variant="filled"
            startAdornment={<Icon type="search" size="m" color="grey300" />}
            endAdornment={
              <span onClick={handleClear} hidden={!inputValue}>
                <Icon type="close" size="m" />
              </span>
            }
          />
        )}
        <ul>
          {visibleOptions.map((option) => (
            <li key={option.id}>
              <StyledOption
                onClick={() => handleSelect(option)}
                hidden={filterCurrentOption ? option.id === value?.id : false}
              >
                {option.name}
              </StyledOption>
            </li>
          ))}
        </ul>
      </StyledWrapper>
    </Popper>
  );
};
