import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PointStatus } from '@/utils/constants';

import { PayloadSocketParams, State } from './types';
import {
  getDevices,
  getPoints,
  pointsTrajectoriesRecalculate,
  resetModerationEvent,
  updateEventStatus,
  setTrackStatus,
  updateTrackStatus,
} from './thunks';

const initialState: State = {
  socketQueryParams: null,
  statistic: {},
  points: new Map(),
  heartbeats: new Map(),
  ellipses: new Map(),
  trackStatuses: new Map(),
};

const slice = createSlice({
  name: 'realtime-map',
  initialState,
  reducers: {
    clear: () => initialState,
    updateSocketParams: (state, action: PayloadAction<PayloadSocketParams | null>) => {
      state.socketQueryParams = action.payload;
    },
    removeByTime: (
      state,
      action: PayloadAction<{
        points: Set<number>;
        ellipses: Set<number>;
      }>
    ) => {
      const newPoints = new Map(state.points);
      const newEllipses = new Map(state.ellipses);

      newPoints.forEach((_, key) => {
        if (action.payload.points.has(key)) {
          newPoints.delete(key);
        }
      });

      newEllipses.forEach((_, key) => {
        if (action.payload.ellipses.has(key)) {
          newEllipses.delete(key);
        }
      });

      state.points = newPoints;
      state.ellipses = newEllipses;
    },
    update: (state, action: PayloadAction<Paths.PointsRealtimeMap.Get.Responses.$200>) => {
      if (action.payload.data) {
        const { points, dumped_ellipses, moderation_updates, track_statuses } = action.payload.data;
        if (points) {
          const map = new Map(state.points);

          points.forEach((item) => {
            map.set(item.id as number, item);
          });
          if (moderation_updates) {
            moderation_updates.forEach(([id, value]) => {
              if (value) map.delete(id);
            });
          }
          state.points = map;
        }
        if (dumped_ellipses) {
          const map = new Map(state.ellipses);
          dumped_ellipses.forEach((item) => {
            map.set(item.id as number, item);
          });
          state.ellipses = map;
        }

        if (track_statuses) {
          const map = new Map(state.trackStatuses);
          track_statuses.forEach((item) => {
            map.set(item.id as number, item);
          });
          state.trackStatuses = map;
        }
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getPoints.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.points) {
          state.points = new Map(action.payload.points.map((item) => [item.id as number, item]));
        }
        if (action.payload.dumped_ellipses) {
          state.ellipses = new Map(action.payload.dumped_ellipses.map((item) => [item.id as number, item]));
        }
        if (action.payload.track_statuses) {
          state.trackStatuses = new Map(action.payload.track_statuses.map((item) => [item.id as number, item]));
        }
      }
    });

    builder.addCase(setTrackStatus.fulfilled, (state, action) => {
      if (action.payload && action.payload.id) {
        const map = new Map(state.trackStatuses);
        map.set(action.payload.id, action.payload);
        state.trackStatuses = map;
      }
    });

    builder.addCase(getDevices.fulfilled, (state, action) => {
      if (action.payload && action.payload.heartbeats) {
        state.heartbeats = new Map(action.payload.heartbeats.map((item) => [item.id as number, item]));
      }

      if (action.payload && action.payload.agent_stats) {
        state.statistic = action.payload.agent_stats;
      }
    });

    builder
      .addCase(updateEventStatus.fulfilled, (state, action) => {
        if (action.payload && action.payload.point && action.payload.point.id) {
          const map = new Map(state.points);
          map.set(action.payload.point.id, action.payload.point);
          state.points = map;
        }
      })
      .addCase(updateEventStatus.rejected, (state, action) => {
        if (action.payload) {
          const data = action.payload as Paths.PointsModeration$ReportId.Post.Responses.$400;
          if (data && data.data && data.data.point && data.data.point.id) {
            const map = new Map(state.points);
            map.set(data.data.point.id, data.data.point);
            state.points = map;
          }
        }
      });

    builder.addCase(updateTrackStatus.fulfilled, (state, action) => {
      if (action.payload && action.payload.points_to_ours) {
        const map = new Map(state.points);
        action.payload.points_to_ours.forEach((id) => {
          const point = map.get(id);
          if (point) map.set(id, { ...point, status: PointStatus.OUR });
        });

        state.points = map;
      }
    });

    builder
      .addCase(resetModerationEvent.fulfilled, (state, action) => {
        if (action.payload && action.payload.point && action.payload.point.id) {
          const map = new Map(state.points);
          map.set(action.payload.point.id, action.payload.point);
          state.points = map;
        }
      })
      .addCase(resetModerationEvent.rejected, (state, action) => {
        if (action.payload) {
          const data = action.payload as Paths.PointsSendForModeration$ReportId.Post.Responses.$400;
          if (data && data.data && data.data.point && data.data.point.id) {
            const map = new Map(state.points);
            map.set(data.data.point.id, data.data.point);
            state.points = map;
          }
        }
      });
  },
});

export const actions = {
  ...slice.actions,
  getPoints,
  resetModerationEvent,
  updateEventStatus,
  getDevices,
  pointsTrajectoriesRecalculate,
  setTrackStatus,
  updateTrackStatus,
};

export default slice.reducer;
