import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { createRegion, deleteRegion, getRegions, getCapturedRegions } from './thunks';

const initialState: State = {
  items: [],
  captured: [],
  isLoading: false,
};

const slice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    clear: (state) => {
      state.items = [];
      state.isLoading = true;
    },
  },
  extraReducers(builder) {
    builder.addCase(getRegions.fulfilled, (state, action) => {
      state.items = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getCapturedRegions.fulfilled, (state, action) => {
      if (action.payload) state.captured = action.payload;
    });
  },
});

export const actions = {
  ...slice.actions,
  getRegions,
  deleteRegion,
  createRegion,
  getCapturedRegions,
};

export default slice.reducer;
