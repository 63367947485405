import { LatLngExpression } from 'leaflet';

import { TileLayer } from '@/components/Map';

export enum ROLES {
  ADMIN = 'admin',
  NETWORK_MANAGER = 'network_manager',
  SOUND_APP = 'sound_app',
  SOUND_MASTER = 'sound_master',
  MODERATOR_MANAGER = 'moderator_manager',
  MODERATOR_MANAGER_SUB = 'moderator_manager_sub',
  REALTIME = 'realtime',
  DEMO_MANAGER = 'demo_manager',
  DEVICE_MANAGER = 'device_manager',
  COMMAND_POST = 'command_post',
  BIRDWATCHER = 'birdwatcher',
  VERIFICATOR = 'verificator',
}

export enum ORDER_BY {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum TIME_UNITS {
  MIN = 'minutes',
  HOUR = 'hours',
  DAY = 'days',
  WEEK = 'weeks',
}

export enum ERROR_CODES {
  AUTH_ACCOUNT_NOT_IN_SYSTEM = 'invite_token_missing_for_new_user',
}

export enum PointStatus {
  HIGH_CONFIDENCE = 'high_confidence',
  HC = 'hc',
  OUR = 'our',
  EVENT = 'event',
  AUTO_MODERATED = 'automoderated',
  CANDIDATE = 'candidate',
  FOR_MODERATION = 'for_moderation',
  CANCELLED = 'cancelled',
}

export const DEFAULT_LIMIT = 15;
export const DEFAULT_LIMIT_OPTION = { id: DEFAULT_LIMIT, name: `${DEFAULT_LIMIT}` };
export const LIMIT_OPTIONS = [5, 15, 50, 100].map((el) => ({ id: el, name: el.toString() }));

export const FLYOUT_CLASS_ORDER = ['0', '1', '2'];

const [lat, lon] = process.env.REACT_APP_MAP_CENTER.split(',').map((v) => Number(v.trim()));

export const CENTER_MAP: LatLngExpression = {
  lat: lat,
  lng: lon,
};

export const ZOOM = process.env.REACT_APP_MAP_ZOOM;

export const LAYERS_CONFIG: TileLayer[] = [
  {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    name: 'Open Street',
    options: {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    },
    mode: 'LIGHT',
  },
  {
    url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    name: 'Sputnik',
    options: {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    },
    mode: 'LIGHT',
  },
  {
    url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    name: 'Map',
    options: {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    },
    mode: 'LIGHT',
  },
  {
    url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
    name: 'Dark Matter',
    options: {
      attribution:
        // eslint-disable-next-line max-len
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20,
    },
    mode: 'DARK',
  },
];
